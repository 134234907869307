<template>
    <div id="page-doctor">
        <div class="bg">
            <div class="doc">
                <img src="../../../public/img/doctor/imgs/doctor.png" style="width: 30%;" class="banner" alt="" />
                <el-tabs @tab-click="tolink" v-model="activeName" :tab-position="tabPosition" type="card" >
                    <el-tab-pane  label="精神科" name="first">
                      <div slot="label" @click="jsk">精神科</div>

                    </el-tab-pane>
                    <el-tab-pane label="心理科" name="second">
                      <div slot="label" @click="xlk">心理科</div>

                        <div class="doctors">

            <img
            :src="data.picture"

            class="docer"
            alt=""
            />


</div>
<div class="article">

    <div class="doctors2">
<el-popover

placement="right"
width="400"
trigger="hover">
<p class="docp">{{data.name}}</p>
<el-button slot="reference" class="popover">
<p class="docp">姓名：{{data.name}}</p>  
</el-button>
</el-popover>

<el-popover

placement="right"
width="400"
trigger="hover">
<p class="docp2">{{data.affiliatedHospital}}</p>
<el-button slot="reference" class="popover">
<p class="docp2">所属医院：{{data.affiliatedHospital}}</p> 
</el-button>
</el-popover>

<el-popover

placement="right"
width="400"
trigger="hover">
<p class="docp2">{{data.professional}}</p>
<el-button slot="reference" class="popover">
<p class="docp2">医生职称：{{data.professional}}</p>
</el-button>
</el-popover>

<el-popover

placement="right"
width="400"
trigger="hover">
<p class="docp2">{{data.advantage}}</p>
<el-button slot="reference" class="popover">
<p class="docp2">擅长：{{data.advantage}}</p>
</el-button>
</el-popover>

</div>

<p class="headerp">
    详细介绍
   
</p>

<p class="p1">

</p>

<p class="p2">
    {{data.introduce}}
</p>

<!-- </div> -->
<!-- </div> -->

<div class="block">
         


         <!-- 弹出框 -->
         <el-button type="text" @click="dialogVisible = true" class="open">点击预约 </el-button>          
         
         
         
         
         
                   </div>


</div>
                    </el-tab-pane>
                    <el-tab-pane label="中医科" name="third">
                      <div slot="label" @click="zyk">中医科</div>

                    </el-tab-pane>
                </el-tabs>
            </div>


           
<!-- 弹出框 -->
<el-dialog
  title="开始预约"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose"
  width:400px
  >

             
            <el-radio-group v-for="item in timeList" :key="item.id" v-model="radio1">
             <div >
              
              <el-radio-button :label="item" >
               
                {{item.doctorName}}
                {{item.time}}
                {{item.timeSlot}}

              </el-radio-button>
             </div>
             
            </el-radio-group>
            
            <p v-if="!this.cpp1" style="font-size: 26px;">当前医生无排班，请选择其他医生预约</p>
            
          <div   v-if="this.cpp1">
            <el-form ref="exam" :rules="rules" label-position="top" :model="form" label-width="50px"
            
            >
            
          <div class="docinput"> 
            <el-form-item label="您的姓名" prop="name" class="docname">
            <el-input size="small" v-model="form.name" ></el-input>
          </el-form-item>

          <el-form-item label="手机" prop="tel">
            <el-input v-model.number="form.tel" value=value.replace(/^[0-9]{11}$/g></el-input>
          </el-form-item>
          </div>

          <el-form-item label="您要咨询的内容" prop="text">
            <el-input v-model="form.text"></el-input>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input type="email" v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item class="button1">
            <el-button type="primary" @click="onSubmit() ">提交</el-button>
          </el-form-item>
         

        </el-form>
          </div>
         
          
 
</el-dialog>
<!-- 弹出框结束 -->


        </div>
    </div>
</template>
<script>
import {
  webDoctorList,
  docTime,
  createAppointment,
  webUserRegister,
} from "@/service/home";
import { telephone } from "../../tools";
export default {
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      }
    };
    var checkText = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      }
    };
    var checkEmail = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error('不能为空'));
      // }
    };
    return {
      dialogVisible: false,
      drawer: false,
      data: {
        age: "",
        certificate: "",
        columnId: "",
        createTime: "",
        education: "",
        gender: "",
        id: "",
        introduce: "",
        mobile: "",
        name: "",
        picturesectionType: "",
        type: "",
      },
      tabPosition: "left",
      activeName: "second",
      timeList: [],
      cpp1: false,
      form: {
        name: "",
        tel: "",
        text: "",
        content: "",
        email: "",
        age: "",
        dateTime: "",
      },
      rules: {
        tel: [{ validator: telephone, trigger: "blur" }],
        name: [{ validator: checkName, trigger: "blur" }],
        text: [{ validator: checkText, trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？", "提示", {
        customClass: "message-logout",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    async getwebDoctorList() {
      try {
        const { data } = await webDoctorList();
        this.data = data.find((row) => {
          return row.id == this.$route.query.id;
        });

        console.log(this.data, "datas");
      } catch (error) {}
    },
    tolink(tab, event) {
      console.log("abc", tab, event);
    },

    jsk() {
      this.$router.push({
        path: "/doctors2",
        query: {
          testQuery: "1",
        },
      });
    },

    xlk() {
      this.$router.push({
        path: "/doctors2",
        query: {
          testQuery: "2",
        },
      });
    },

    zyk() {
      console.log("zzzzz");
      this.$router.push({
        path: "/doctors2",
        query: {
          testQuery: "3",
        },
      });
    },
    async getdocTime() {
      try {
        let params = {
          doctorId: this.$route.query.id,
        };
        const { data } = await docTime(params);
        this.timeList = data;
        console.log(this.timeList, "times");
        console.log(this.timeList.length, "timesss");
        // alert(this.timeList.length)
        if (this.timeList.length >= 1) {
          this.cpp1 = true;
          this.cpp2 = false;
          //   alert("true")
        }
      } catch (error) {}
      // },
    },
    async onSubmit() {
      let a = /^1\d{10}$/;
      let b = false;
      if (a.test(this.form.tel)) {
        if (this.form.name == "") {
          alert("请输入您的姓名");
        } else if (this.form.text == "") {
          alert("请输入你要咨询的内容");
        } else if (this.form.email == "") {
          alert("请输入邮箱");
        } else if (this.radio1 == "") {
          alert("请选择需要预约的医生");
        } else {
          b = true;
        }
      }

      if (b) {
        // 注册
        try {
          const params = {
            // userRegister: {
            age: 0,
            city: "string",
            content: "string",
            createTime: this.radio1.workDate,
            id: 0,
            mailbox: this.form.email,
            mobile: this.form.tel,
            name: this.form.name,
            picture: "string",
            // }
          };
          const { data } = await webUserRegister(JSON.stringify(params));

          console.log(data);
          this.radio3 = data;
        } catch (error) {
          // console.log(error)
        }

        // 预约
        console.log(this.radio1, "测试radio1");
        console.log(this.radio1.id);
        // alert(this.radio3)
        try {
          const params = {
            // subscribe: {
            createTime: this.radio1.workDate,
            userName: this.form.name,
            mobile: this.form.tel,
            content: this.form.text,
            address: "string",
            department: "",
            doctorId: this.radio1.doctorId,
            doctorName: this.radio1.doctorName,
            feeDifference: 0,
            hospital: "string",
            id: 0,
            rosteringId: this.radio1.id,
            serviceCharge: 0,
            source: "官网",
            userId: this.radio3,
            // }
          };
          const { data } = await createAppointment(JSON.stringify(params));

          // const { data } = await createAppointment({subscribe:params})
          console.log(data, "create");

          alert("预约成功");
          this.dialogVisible = false;
        } catch (error) {
          // console.log(error)
        }
        console.log(this.dialogVisible, "true");
      } else {
        alert("信息填写输入有误,请重新输入");
      }
    },
  },
  mounted() {
    this.getwebDoctorList();
    this.getdocTime();
  },
};
</script>

<style lang="scss" scoped>
#app {
  line-height: 0;
}
.bgimg {
  background: center;
  margin-bottom: 40px;
}

.bg {
  background: rgb(242, 242, 242);
  // margin: 0px 5% 0px 5%;
  height: 100%;
}
#page-doctor {
  height: 100%;
}

.banner {
  margin-left: 1%;
  margin-top: 1%;
}
.doc {
  margin-top: 3%;
  // margin-bottom: 5%;
  height: 100%;
}
.docer {
  margin-left: 45%;
  margin-right: 5%;
  width: 320px;
  height: 340px;
}

.doctors {
  display: flex;

  // margin-top: 1%;
  line-height: 25px;
  padding-top: 1%;
}
.doctors2 {
  width: 550px;
  // height: 130px;
  margin-top: 45px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  // 字体溢出
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dimg {
  width: 12%;
  margin-top: 3%;
  display: inline-block;
  margin-right: 3%;
}
.doctors,
p {
  vertical-align: top;
  line-height: 30px;
  // font-size: 10px;
  font-weight: 500;
}
.docp {
  font-size: 40px !important;
  font-weight: bolder;
  margin-bottom: 22px;
  margin-top: 60px;
}
.docp2 {
  font-size: 28px !important;
  line-height: 170%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 540px;
}

.p1,
.p2 {
  margin-bottom: 33px;
  font-size: 26px;
  font-weight: normal;
  text-indent: 3%;
  line-height: 38px;
  letter-spacing: 5px;
}

.headerp {
  font-weight: bolder;
  font-size: 40px;
  // text-indent: 6%;
  margin-top: 60px;
}

.article {
  width: 90%;
  padding: 0 5% 0 5%;
  margin-top: 5%;
  margin-bottom: 3%;
}

//标签页
::v-deep .el-tabs__header {
  background: #fff;
  padding: 2% 2% 2% 2%;
  margin-left: 1%;
}
::v-deep .el-tabs__item.is-active {
  background: red;
  color: #fff;
}
::v-deep .el-tabs__nav {
  text-align: center;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background: #790405;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
}

::v-deep .el-tabs__item.is-active {
  color: #fff;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header {
  border: 0;
  width: 250px;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  text-align: center;
}
::v-deep .el-tabs__item {
  padding: 0;
  margin: 5% 5%;
  width: 142px;
  height: 30px;
  line-height: 30px;
}
::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  text-align: center;
  font-size: 15px;
}
::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  border: 0;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
}
::v-deep .el-tabs__content {
  transform: translateX(-140px);
  // height: 1570px;
}

::v-deep .el-tab-pane {
  width: 100%;
}
.popover {
  border: 0px;
  text-align: left;
  line-height: 150%;
  background: rgb(242, 242, 242);
  padding: 0;
}

::v-deep .el-input__inner {
  height: 40px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 0px 0px #e4e2e2;
  box-shadow: 0px 0px 0px 0px #e4e2e2;
}
.docinput {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.docname {
  margin-right: 40px;
}
.button1 {
  margin-top: 10px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-form-item__label {
  padding: 0;
}
::v-deep .el-form-item__error {
  padding: 0;
}

::v-deep .el-dialog {
  width: 90% !important;
  // margin-left: 130px;
}

.open {
  background-color: #790405;
  color: #fff;
  width: 200px;
  height: 70px;
  font-size: 30px;
  margin-top: 20px;
}
::v-deep .el-dialog__title {
  font-size: 28px;
}
</style>


<style lang="scss">
.message-logout {
  width: 850px;
}
.message-logout p {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.message-logout span {
  font-size: 36px;
}
</style>
<template>
  <div id="app">
    <div class="page-news">
      <div class="mainform">
        <div class="text1">
          <div class="textp">
            <div class="article">
              <p class="headerp">
                {{ newsList.title }}
              </p>

              <p class="p3">
                {{ newsList.author }} &nbsp; {{ newsList.createTime }}
              </p>
              <p class="p1" v-html="newsList.content"></p>

              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { webSelectById, webProjectList } from "@/service/home";

export default {
  data() {
    return {
      newsList: "",
      newsList2: "",
      activeName: "first",
      tabPosition: "left",
    };
  },

  computed: {
    //    alert('1')
  },
  methods: {
    tosearch() {
      this.$router.push("/search");
    },
    async getwebSelectById() {
      // try {
      this.a = this.$route.query.id;
      let parmes = { id: this.a };
      const { data } = await webSelectById(parmes);
      // console.log(a);

      this.newsList = data;
      console.log(data);
      console.log("输出位置3");
      let b = this.newsList.createTime.split("T");
      let d = b[1].split(".");
      this.newsList.createTime = b[0] + " " + d[0];
      console.log(this.newsList.createTime);

      console.log(this.newsList, "aa");
      let listtimes = this.newsList;
      console.log(listtimes, "bb");

      // listtimes = Array.from(listtimes);
      listtimes.forEach(function (item) {
        console.log(item, "item");

        let moment = require("moment");
        let date = moment(new Date(item.createTime)).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log(date);
      });
    },
    async getwebProjectList() {
      // console.log(1000)
      try {
        const { data } = await webProjectList();
        this.newsList2 = data;

        console.log("输出位置1");
        console.log(this.newsList2);
        console.log("输出位置2");
        console.log(this.newsList);
      } catch (error) {}
    },

    timechange() {},
  },
  mounted() {
    this.getwebSelectById();
    this.getwebProjectList();
    this.timechange();
    //  alert(this.newsList.createTime)
  },
};
</script>

<style lang="scss" scoped>
#app {
  line-height: 0;
}

.mainform {
  text-align: left;
  padding: 0 15% 0 20%;
  // margin-bottom: 2%;
}

.text1 {
  margin-bottom: 2%;
  display: flex;
  //background:rgb(242, 242, 242);
  justify-content: flex-start;
}
.textp {
  width: 100%;
}

.p1,
.p2 {
  margin-bottom: 10px;
  font-weight: 500;
  //text-indent:3%;
  font-size: 8px;
}
.p3 {
  margin-bottom: 10px;
  font-weight: lighter;
  //text-indent:3%;
  font-size: 1%;
}

.headerp {
  font-weight: bolder;
  font-size: 20px;
  //text-indent:6%
}

.article {
  width: 90%;
  // padding: 0 5% 0 5%;
  margin-top: 3%;
  margin-bottom: 3%;
  line-height: 200%;
}
.article img {
  width: 100%;
}
.el-link {
  font-size: 8px;
  margin-bottom: 3%;
}
.el-link.el-link--success.is-underline:hover:after,
.el-link.el-link--success:after {
  border-color: #000;
}
</style>

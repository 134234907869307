<template>
  <div id="app">
    <h1 style="font-size: 0">安思医院关于我们</h1>
    <div class="page-f">
      <!-- 轮播图 -->
      <el-carousel :interval="4000" height="440px">
        <div v-for="item in newsList" :key="item.id">
          <el-carousel-item v-if="item.columnName == '网站首页banner'">
            <img :src="item.picture" class="bannerHeight" />
          </el-carousel-item>
        </div>
      </el-carousel>
      <!-- 轮播图结束 -->

      <div class="mainform">
        <div class="text1" v-for="item in newsList" :key="item.id">
          <div class="text1" v-if="item.columnName == '关于-公司简介'">
            <div class="textp">
              <img
                style="width: 25%"
                src="../../../public/img/f/logo1.png"
                alt=""
              />

              <el-popover placement="bottom" width="900" trigger="hover">
                <p
                  class="p1 pp1"
                  v-html="item.content"
                  style="white-space: pre-wrap"
                ></p>

                <el-button slot="reference" class="popover">
                  <p
                    class="p1 pp1"
                    v-html="item.content"
                    style="white-space: pre-wrap"
                  ></p>
                </el-button>
              </el-popover>
            </div>

            <div class="textimg">
              <img
                style="width: 400px; height: 350px; margin-top: 180px"
                :src="item.picture"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="text2" v-for="item in newsList" :key="item.id">
          <div class="text2" v-if="item.columnName == '关于-企业文化'">
            <img
              class="logo2"
              style="width: 30%"
              src="../../../public/img/f/logo2.png"
              alt=""
            />

            <div class="ptext">
              <p class="p1" style="font-size: 34px; margin-bottom: 20px">
                团队服务
              </p>
              <div>
                <p class="p2">
                  <el-popover placement="bottom" width="900" trigger="hover">
                    <p
                      class="p2"
                      v-html="item.content"
                      style="white-space: pre-wrap"
                    ></p>

                    <el-button slot="reference" class="popover2">
                      <p
                        class="p2"
                        v-html="item.content"
                        style="white-space: pre-wrap"
                      ></p>
                    </el-button>
                  </el-popover>
                </p>
              </div>
            </div>

            <div class="text2img">
              <img
                class="iconimg"
                src="../../../public/img/f/icon1.png"
                alt=""
              />
              <span>专业</span>
              <img class="icon" src="../../../public/img/f/icon.png" alt="" />

              <img
                class="iconimg"
                src="../../../public/img/f/icon2.png"
                alt=""
              />
              <span>经验</span>
              <img class="icon" src="../../../public/img/f/icon.png" alt="" />

              <img
                class="iconimg"
                src="../../../public/img/f/icon3.png"
                alt=""
              />
              <span>创意</span>
              <img class="icon" src="../../../public/img/f/icon.png" alt="" />

              <img
                class="iconimg"
                src="../../../public/img/f/icon4.png"
                alt=""
              />
              <span>服务</span>
            </div>
          </div>
        </div>

        <div class="text3">
          <img
            class="logo3"
            style="width: 25%"
            src="../../../public/img/f/logo3.png"
            alt=""
          />

          <img src="../../../public/img/f/text3.png" alt="" />
        </div>

        <div class="text4">
          <p class="aboutus">About us</p>
          <p class="docp">北京安思京顺精神科诊所</p>
          <!-- 三个图片轮播 -->
          <div class="three">
            <div class="threeimg">
              <el-carousel type="card" :interval="4000" height="250px">
                <div v-for="item in newsList" :key="item.id">
                  <el-carousel-item v-if="item.columnName == '首页-720展示'">
                    <a
                      target="_blank"
                      href="https://www.720yun.com/t/762jv7mktv8?scene_id=21054657"
                    >
                      <img :src="item.picture" />
                    </a>
                  </el-carousel-item>
                </div>
              </el-carousel>
            </div>
          </div>
          <!-- </a> -->

          <div class="more">
            <el-button style="width: 200px; height: 60px">
              <a
                target="_blank"
                href="https://www.720yun.com/t/762jv7mktv8?scene_id=21054657"
              >
                more +
              </a>
            </el-button>
          </div>
        </div>

        <div class="text5">
          <img
            class="logo3"
            style="width: 25%"
            src="../../../public/img/f/text5.1.png"
            alt=""
          />
          <div class="tel">
            <div class="text5span">
              <div>
                地址:北京市朝阳区京顺东街6号院6号楼5层 <br />
                电话:010-64358277 <br />
                电话:010-64358819 <br />
              </div>

              <div style="margin-left: 40px; margin-top: 20px">
                手机:132 41631540 <br />
                邮箱:13901385391@163.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { webNewsList } from "@/service/home";

export default {
  data() {
    return {
      newsList: [],

      imgList: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],
    };
  },
  methods: {
    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsList = data;

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort == 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort != 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }

        this.newsListjian.list = a4;

        // 置顶结束
      } catch (error) {}
    },
  },
  mounted() {
    this.getNewsList();
  },
};
</script>

<style lang="scss" scoped>
.bannerHeight {
  width: 100%;
}

a {
  text-decoration: none;
}
#app {
  line-height: 0;
}
.page-d {
  line-height: 10px;
  text-align: left;
}
.bg {
  background: center;
  margin-bottom: 80px;
}
.mainform {
  // padding: 0px 5% 0px 5%;
  text-align: left;
}

.text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.textp {
  width: 950px;
  height: 240px;
  text-align: left;
}
.textp > p {
  font-size: 8px;
  line-height: 20px;
  text-indent: 2em;
  margin-right: 40px;
}

.textp > img {
  margin-bottom: 40px;
}

.text2 {
  margin: 40px 0px 20px 0px;
}

.ptext {
  width: 100%;
  background: #790405;
  text-align: center;
  line-height: 5%;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
  height: 380px;
}
.p1 {
  font-weight: normal;
  margin-bottom: 15px;

  padding: 0 5% 0 5%;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
  // width: 800px;
  // height: 600px;
  // padding-bottom: 1%;
  // padding-top: 3%;
}
.pp1 {
  width: 800px;
  height: 300px;
  overflow: hidden;
}
.p2 {
  text-align: left;
  padding: 0 0% 0 10%;

  text-indent: 3%;
  font-weight: 300;
  padding-bottom: 2%;
  margin-top: -10px;
  width: 800px;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
}

.logo2 {
  margin-bottom: 40px;
}
.text2img {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  margin-bottom: 4%;
}
.iconimg {
  margin: 0 0 0 0;
  width: 8%;
}
.icon {
  margin: 0 0 0 5%;
  width: 4%;
}
.text2img span {
  margin: 0 0 0 1%;
  font-size: 28px;
}
.logo3 {
  margin-bottom: 20px;
}
.text3 {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // margin-left: -58px;
}

.text4 {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 12%;
}

.text4 img {
  margin: 20px 10px 20px 10px;
}
.text5 {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-button {
  border: 2px solid rgb(121, 4, 5);
  width: 890px;
  height: 340px;
  font-size: 28px;
  color: #000;
  font-weight: bold;
  line-height: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.text {
  margin-bottom: 60px;
}

.aboutus {
  font-size: 13rem;
  font-weight: normal;
  color: #f4f7fb;
  letter-spacing: -15px;
}

.imgtext {
  display: flex;
  justify-content: center;
  width: 100%;
}
.imgtext img {
  width: 30%;
}

.docp {
  font-size: 34px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #790405;
  margin-bottom: 3%;
}
::v-deep .el-carousel--horizontal {
  margin-bottom: 2%;
}

.tel {
  width: 100%;
  height: 400px;
  background-image: url(../../../public/img/f/text5.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 80%;
  display: flex;
  display: flex;
  justify-content: center;
}

.text5span {
  transform: translate(-9rem, -12rem);
  font-size: 12px;
  line-height: 200%;
  margin-left: 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.three img {
  width: 100%;
}

.popover {
  border: 0px;
  text-align: left;
  line-height: 20px;
  width: 950px;
  // margin-left: -200px;
  font-size: 8px;
  // margin-top: 200px;
}

.popover2 {
  border: 0px;
  text-align: left;
  line-height: 20px;
  // width: 700px;
  margin-left: -100px;
  font-size: 8px;
  background: #790405;
  color: #fff;
  // margin-top: -200px;
}
.more {
  display: flex;
  justify-content: center;
}
</style>
<template>
  <div id="a">
    <el-container>
      <el-header>
        <div id="b">
          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            :router="true"
            background-color="#fff"
            text-color="#000"
            active-text-color="#fff"
          >
            <template>
              <el-menu-item index="/home_pho">网站首页</el-menu-item>

              <el-menu-item index="/medical_pho">诊疗项目</el-menu-item>
              <el-menu-item index="/publicize_pho">医学科普</el-menu-item>
              <el-menu-item index="/assess_pho">在线测评</el-menu-item>
              <el-menu-item index="/bespeak_pho">在线约诊</el-menu-item>
              <el-menu-item index="/contact_pho">关于我们</el-menu-item>
            </template>
          </el-menu>
        </div>
      </el-header>

      <el-main class="main_height">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="footer">
          <ul>
            <li><p style="font-size: 16px; margin-bottom: 5px">关于我们</p></li>
            <li>中心简介</li>
            <li>团队动态</li>
            <li>联系我们</li>
          </ul>
          <ul>
            <li><p style="font-size: 16px; margin-bottom: 5px">新闻中心</p></li>
            <li>企业动态</li>
            <li>企业风采</li>
          </ul>
          <ul>
            <li><p style="font-size: 16px; margin-bottom: 5px">医学科普</p></li>
            <li>厌食</li>
            <li>焦虑</li>
            <li>妄想</li>
            <li>抑郁</li>
            <li>强迫</li>
          </ul>

          <ul>
            <li><p style="font-size: 16px; margin-bottom: 5px">联系我们</p></li>
            <li>电话: 010-64358277 010-64358819</li>
            <li>邮箱: 132 41631540</li>
            <li style="font-size: 0px">妄想</li>
            <li>地址: 北京市朝阳区京顺东街6号院6号楼5层</li>
          </ul>
          <ul class="asjskf">
            <li class="asjskfli">
              <img width="90px" src="../../public/img/code.png" alt="" />
              <p>微信公众号</p>
            </li>
            <li style="margin-left: 20px" class="asjskfli">
              <img width="90px" src="../../public/img/asjskf.jpg" alt="" />
              <p>客服微信</p>
            </li>
            <!-- <li>官方公众号</li> -->
          </ul>
        </div>
      </el-footer>

      <div class="end">
        <p>
          版权所有: 安思京顺精神科诊所
          <span>
            <a
              href="https://beian.miit.gov.cn"
              target="_blank"
              style="text-decoration: none; color: #fff"
              >京ICP备2023002990号-1</a
            >
          </span>
        </p>
      </div>
    </el-container>
  </div>
</template>

<script>
import { webNewsList, homeLoad } from "@/service/home";
export default {
  name: "index",
  data() {
    return {
      imgLists: [
        { id: 0, idView: require("../../public/img/bg1.png") },
        { id: 1, idView: require("../../public/img/bg1.png") },
        { id: 2, idView: require("../../public/img/bg1.png") },
        { id: 3, idView: require("../../public/img/bg1.png") },
      ],
      input: "",
      bannerHeight: "",

      activeIndex: "1",
      activeIndex2: "1",
      newsList: [],
    };
  },

  methods: {
    imgLoad() {},

    async gethomeLoad() {
      try {
        const { data } = await homeLoad();
        this.newsList = data;
      } catch (error) {}
    },
    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsList = data;
      } catch (error) {}
    },
  },
  mounted() {
    window.onresize = function () {
      console.log(111);
      let appDom = document.querySelector("#app");
      if (window.innerWidth === screen.width) {
        document.body.style.zoom = 1;
      } else if (window.innerWidth < screen.width) {
        document.body.style.zoom = "";
      }
    };
    let u = navigator.userAgent;
    if (u.match(/AppleWebKit.*Mobile.*/) != null) {
      document.getElementsByClassName("cardone")[0].style = "width:80%";
    }
    this.getNewsList();
    // this.gethomeLoad()
  },
};
</script>

<style lang="scss" scoped>
.main_height {
  min-height: 1500px;
}
.bannerHeight {
  width: 100%;
}
#b {
}
#b > ul {
  // height: 59px;
  display: flex;
  justify-content: space-around;
  flex-flow: nowrap;
}
.logoimg {
  height: 140px;
  margin-top: 10px;
}

.el-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-menu > img {
  background: center;
  width: 15%;
  padding-right: 50px;
}
::v-deep .el-header {
  position: sticky;
  top: 0;
  z-index: 999;

  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  padding: 0px;
  height: 80px !important;
}
::v-deep .el-footer {
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  background: rgb(121, 4, 5);
  padding: 0px 5% 0px 11%;
  line-height: 30px !important;
  height: 220px !important;
  display: flex;
  justify-content: center;
  // padding-left: 5%;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

::v-deep .el-main {
  // background-color: #E9EEF3;
  color: #333;

  padding: 0px 0px 0px 0px;
  background: #ffffff;
}

.el-menu-item.is-active {
  background-color: rgb(181, 12, 12) !important;
  border-bottom-color: rgb(181, 12, 12) !important;
}
.el-menu-item {
  width: 16%;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
}

.bannerHeight {
  height: 460px;
  // width: 1264px;
}

.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

// 卡片样式

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

::v-deep .el-row {
  display: flex;
  justify-content: space-between;
}

.card1 {
  line-height: 15px;
  text-align: left;
  width: 380px;
}
.maxcard {
  padding-left: 40px;
}
.card1 p {
  font-weight: bold;
  font-size: 14px;
  word-spacing: 10px;
  margin: 10px 0px 10px 0px;
  display: inline-block;
}
.card1 > p {
  margin: 10px 0px 10px 0px;
}
.card1 > span {
  font-size: 10px;
}
.card3 > span {
  font-size: 10px;
}
.card2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.pspan {
  font-size: 14px;
  font-weight: bold;
}
.card3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}
.ansi {
  margin: 40px 0px 0px 0px;
  width: 50%;
}
.mimg {
  margin: 10px 0px 10px 0px;
}
.cardone {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}
.cardone ul {
  list-style: none;
  padding: 0;
  margin: 20px 0px 0px 0px;
}
.cardone li {
  margin-top: 10px;
}
.card-span2 {
  padding-bottom: 10px;
  font-size: 10px;
}
.el-link {
  font-size: 12px;
  margin-top: 10px;
  color: #a31010;
  font-weight: bold;
}

.title {
  // text-align: center !important;
  line-height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.time {
  display: flex;
  // white-space: wrap;
  flex-wrap: wrap;
  // justify-content: space-between;
}
.date {
  font-weight: bold;
  font-size: 18px !important;
  padding-left: 15px;
}
.year {
  font-size: 10px;
}
.news {
  padding-left: 50px;
  font-size: 10px;
}
.el-button {
  margin: 10px 0px 0px -40px;
  background-color: #df1c1c;
  border-color: #df1c1c;
  border-radius: 0%;
}
.cardone {
  margin-bottom: 40px;
}
.cardtwo {
  padding: 0px 250px 0px 250px;
  text-align: start;
  line-height: 0px;
}

::v-deep.el-input__inner {
  height: 200px !important;
}
::v-deep .el-input__inner {
  height: 60px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
}
::v-deep .search {
  width: 200px;
  background: #a31010 !important;
  height: 60px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
}
::v-deep .el-icon-search {
  color: #fff;
  font-size: 30px;
}

// 标题
.server {
  margin-top: 20px;
  line-height: 20px;
  color: #df1c1c;
}
.server1 {
  line-height: 20px;
  padding: 30px 200px 30px 200px;
  color: #df1c1c;
}

.server > p {
  font-weight: bold;
  font-size: 18px;
}
.server > p > span {
  font-weight: lighter;
  font-size: 12px;
}
.server1 > p {
  font-weight: bold;
  font-size: 18px;
}
.server1 > p > span {
  font-weight: lighter;
  font-size: 12px;
}
.serverimg {
  width: 100%;
  padding: 20px 0px 40px 0px;
}

// 标题2
.server1 > ul {
  display: flex;
  flex-wrap: nowrap;
}
.server1 > ul > li > p {
  font-weight: bold;
  font-size: 18px;
}
.server1 > ul > li > span {
  font-size: 14px;
  // padding: 20p 0px 20px 0px;
}
.server1 > ul > li > img {
  width: 100%;
}

// 仨个图
.three {
  padding: 0px 10% 0px 10%;
  height: 280px;
}

// 底部
.footer {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.el-footer ul {
  margin: 0;
  color: #fff;
  font-size: 12px;
  padding-left: 0px;
}
.el-footer li {
  margin: 0;
}
.footer > .nav > p {
  padding-top: 20px;
  font-size: 14px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul > li {
  font-size: 8px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul {
  float: left;
  //  margin-right: 30px;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
}
.end {
  background: rgb(121, 4, 15);
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.end > p {
  // margin-left: 450px;
  font-weight: lighter;
  // padding-top: 20px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  // letter-spacing: 1px;
}
.end > p span {
  margin-left: 40px;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
.foots {
  display: flex;
  margin-top: 15px;
  justify-content: space-around;
}
.foots ul li {
  font-size: 18px;
}
.asjskf {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.asjskfli {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<template>
  <div class="page-d">
    <h1 style="font-size: 0">安思医院测评</h1>
    <!-- 轮播图 -->
    <el-carousel :interval="4000" height="440px">
      <div v-for="item in newsListban" :key="item.id">
        <el-carousel-item v-if="item.columnName == '网站首页banner'">
          <img :src="item.picture" class="bannerHeight" />
        </el-carousel-item>
      </div>
    </el-carousel>
    <!-- 轮播图结束 -->

    <div class="mainform">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        :tab-position="tabPosition"
      >
        <el-tab-pane
          v-for="item in newsListtype"
          :key="item.id"
          :label="item.name"
          :name="item.id"
          @click="getwebList()"
        >
          <el-form label-width="400px" :model="data" ref="data" id="myForm">
            <div v-for="(items, i) in newsList2" :key="i">
              <div v-if="items.type == item.id">
                <br />
                <div class="radios">
                  <!-- 单选 -->
                  <div v-if="items.category == 1">
                    <el-form-item :label="items.problem">
                      <span
                        v-for="(txt, index) in items.questionOptions"
                        :key="index"
                      >
                        <el-radio-group v-model="lscf[i]">
                          <el-radio
                            :label="txt.option"
                            @click.native="q(txt, 1)"
                            >{{ txt.option }}</el-radio
                          >
                        </el-radio-group>
                      </span>
                    </el-form-item>
                  </div>

                  <!-- 多选 -->
                  <div v-if="items.category == 2">
                    <el-form-item :label="items.problem" prop="resource3">
                      <span
                        v-for="txt in items.questionOptions"
                        :key="txt.option"
                      >
                        <el-checkbox-group v-model="checkList">
                          <el-checkbox
                            :label="txt.option"
                            @click.native="q(txt, 2)"
                            >{{ txt.option }}</el-checkbox
                          >
                        </el-checkbox-group></span
                      >
                    </el-form-item>
                  </div>

                  <!-- 填空 -->
                  <div v-if="items.category == 3">
                    <el-form-item :label="items.problem">
                      <el-input
                        size="small"
                        v-model="aaac[i]"
                        @blur="q(items, 3, aaac[i])"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>

            <div class="nametel">
              <el-form
                ref="exam"
                :rules="rules"
                label-position="top"
                :model="data"
                label-width="50px"
              >
                <el-form-item label="您的姓名" prop="name">
                  <el-input
                    size="small"
                    v-model="data.name"
                    class="nameint"
                  ></el-input>
                </el-form-item>
              </el-form>

              <el-form
                ref="exam"
                :rules="rules"
                label-position="top"
                :model="data"
                label-width="50px"
              >
                <el-form-item label="电话" prop="tel">
                  <el-input
                    size="small"
                    v-model="data.tel"
                    class="nameint"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>

            <el-form
              ref="exam"
              :rules="rules"
              label-position="top"
              :model="data"
              label-width="50px"
            >
              <el-form-item label="邮箱" prop="email">
                <el-input
                  size="small"
                  v-model="data.email"
                  class="nameinte"
                ></el-input>
              </el-form-item>
            </el-form>

            <el-form-item>
              <el-button type="primary" @click="submitForm()" class="butform"
                >提交</el-button
              >
              <el-button @click="resetForm(data)" class="resmove"
                >重置</el-button
              >
              <!-- <a href="#top">返回顶部</a> -->
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <!-- </div> -->
      </el-tabs>
    </div>
  </div>
</template>
  <script>
import {
  webList,
  webNewsList,
  webCreate,
  webUserRegister,
  evaluation,
} from "@/service/home";
import { telephone } from "../../tools";

export default {
  name: "index",
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      } else {
        return callback(); //没有会使this.$refs[formName].validate有问题
      }
    };
    return {
      aaac: [],
      checkList: [],
      llk: 1,
      tabPosition: "left",
      newsListban: [],
      newsList2: [],
      newsListtype: [],
      datatype: {
        createTime: "",
        nmae: "",
        id: "",
      },
      imgList: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],

      problem: {
        name: "",
      },
      z: [],
      lscf: [""],
      z1: false,
      z2: false,
      z3: false,
      newsList: "",
      activeName: 1,
      arrnum: 123,
      data: {
        tel: "",
        name: "",
        email: "",
        num: 2,
        resource: "",
        resource2: "",
        resource3: [],
        resource4: "",
        resource5: "",
      },
      rules: {
        tel: [{ validator: telephone, trigger: "blur" }],
        name: [{ validator: checkName, trigger: "blur" }],
        // email: [{ validator: checkEmail, trigger: "blur" }],
      },
      // resource2:[],
      abc: {
        a: "",
        b: "",
        c: "",
        d: "",
      },
      list2: [
        { id: 1, name: "是" },
        { id: 2, name: "否" },
      ],
      // list:[
      //   { id },
      // ]
    };
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      this.getwebList();
    },

    async submitForm() {
      // 注册
      try {
        const params = {
          // userRegister: {
          age: 0,
          city: "string",
          content: "string",
          createTime: new Date(),
          id: 0,
          mailbox: this.data.email,
          mobile: this.data.tel,
          name: this.data.name,
          picture: "string",
          // }
        };
        const { data } = await webUserRegister(JSON.stringify(params));

        // const { data } = await createAppointment({subscribe:params})
        this.radio3 = data;
      } catch (error) {}

      for (let i = 0; i < this.z.length; i++) {
        this.z[i].questionOptionsId = this.z[i].questionOptionsId + "";
        this.z[i].questionOptionsId = this.z[i].questionOptionsId.replace(
          /a/g,
          ","
        );
      }

      var json = JSON.stringify(this.z);

      let a = /^1\d{10}$/;
      let b = false;

      if (a.test(this.data.tel)) {
        // alert("你的手机号码输入正确")
        if (this.data.name == "") {
          alert("请输入您的姓名");
        } else {
          // alert("你的名字输入正确")
          if (this.data.email == "") {
            alert("请输入你的邮箱");
          } else {
            // alert("你的邮箱输入正确")
            b = true;
          }
        }
      } else {
        alert("请输入正确的手机号码");
      }

      if (b) {
        // alert("zhdsfffds")

        // 提交测评
        try {
          const params = {
            userName: this.data.name,
            mobile: this.data.tel,
            email: this.data.email,

            createTime: new Date(),
            id: 0,
            optionsResult: json,
            type: this.activeName,
            id: 0,
            userId: this.radio3,
            // }
          };
          const { data } = await webCreate(JSON.stringify(params));

          alert("提交成功");
        } catch (error) {}
      } else {
        alert("信息填写输入有误,请重新输入");
      }
    },

    resetForm() {
      // location. reload();

      let name3 = JSON.parse(JSON.stringify(this.aaac));
      name3.length = 0;
      this.aaac = name3;
      // alert(this.checkList)
      let name4 = JSON.parse(JSON.stringify(this.checkList));
      name4 = [];
      this.checkList = name4;
      // alert(this.checkList)

      this.data.email = "";
      this.data.tel = "";
      this.data.name = "";
      let name2 = JSON.parse(JSON.stringify(this.lscf));

      name2.length = 0;
      //执行完业务代码后
      this.lscf = name2;
      this.z.length = 0;
    },

    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsListban = data;

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort == 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort != 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }

        this.newsListjian.list = a4;

        // 置顶结束
      } catch (error) {}
    },

    async getevaluation() {
      try {
        const { data } = await evaluation();
        this.newsListtype = data;
        this.datatype = data;
      } catch (error) {}
    },

    async q(a, b, c) {
      if (b == 2) {
        this.z1 = !this.z1;
      }
      if (b != 2 || (b == 2 && this.z1)) {
        for (let i in this.z) {
          if (b == 1 || b == 2) {
            if (a.problemId == this.z[i].problemId) {
              if (b == 2) {
                this.z[i].questionOptionsId = this.z[i].questionOptionsId + "";

                var b = this.z[i].questionOptionsId.split("a");

                for (let w = 0; w < b.length; ) {
                  if (b[w] == a.id) {
                    this.z[i].questionOptionsId = "";
                    for (let e = 0; e < b.length; e++) {
                      if (b[e] != a.id) {
                        if (this.z[i].questionOptionsId == "") {
                          this.z[i].questionOptionsId = b[e] + "";
                        } else {
                          this.z[i].questionOptionsId =
                            this.z[i].questionOptionsId + "a" + b[e];
                        }
                      }
                    }

                    if (this.z[i].questionOptionsId == "") {
                      this.z.splice(i, 1);
                    }
                    this.z3 = true;
                  } else {
                    //                     alert("557")
                  }
                  w = w + 1;
                }
                if (!this.z3) {
                  this.z[i].questionOptionsId =
                    this.z[i].questionOptionsId + "a" + a.id;
                }
                this.z3 = false;
              } else {
                this.z[i].questionOptionsId = a.id;
                this.z2 = true;
              }
            }
          }
          if (b == 3) {
            if (a.id == this.z[i].problemId) {
              this.z[i].questionOptionsId = c;
              this.z2 = true;
            }
          }
        }
        if (!this.z2) {
          if (b == 1 || b == 2) {
            this.z.push({ problemId: a.problemId, questionOptionsId: a.id });
          }
          if (b == 3) {
            this.z.push({ problemId: a.id, questionOptionsId: c });
          }
        }
        this.z2 = false;
      }
    },
    async getwebList() {
      try {
        let params = {
          type: this.activeName,
        };
        const { data } = await webList(params);
        this.newsList2 = data;
      } catch (error) {}
    },

    changetype() {
      this.a = [];
      for (let i = 0; i < this.newsListtype.length; i++) {
        this.a.push(i + 1);
      }
    },
  },
  mounted() {
    this.getwebList();
    this.getNewsList();
    this.getevaluation();
  },
  updated() {
    this.changetype();
  },
};
</script>
  <style lang="scss" scoped>
.bannerHeight {
  width: 100%;
}
.page-d {
  line-height: 10px;
  text-align: left;
  min-height: 1500px;
}
.bg {
  background: center;
  margin-bottom: 20px;
}
.mainform {
  // padding: 0px 5% 0px 5%;
  text-align: left;
}

::v-deep .el-form-item__label {
  // margin-left: -90px ;
  text-align: left;
  width: 100% !important;
  font-size: 30px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
::v-deep .el-radio__inner {
  width: 30px;
  height: 30px;
}
::v-deep .el-radio-group {
  margin-right: 5%;
}
::v-deep .el-radio__label {
  font-size: 28px;
  margin-top: 10px;
  letter-spacing: 2px;
}
::v-deep .el-checkbox__label {
  font-size: 28px;
  letter-spacing: 2px;
}
::v-deep .el-checkbox__inner {
  width: 30px;
  height: 30px;
}
::v-deep .el-ckeckbox-group {
  margin-right: 5% !important;
}

.el-radio {
  line-height: 25px;
}
.radio1-2 {
  margin-left: 55px;
}
// .el-radio:nth-child(2){
//   margin-left: 55px;

// }
.radio1-3 {
  margin-left: 55px;
}
.radio1-4 {
  margin-left: 48px;
}
.radio1-5 {
  margin-left: 55px;
}

.group1 {
  margin-left: -928px;
}
.group2 {
  margin-left: -928px;
}
.group3 {
  margin-left: -928px;
}
.el-button--primary {
  margin-left: -340px;
}

.radio2-1 {
  margin-left: 60px;
}
.radio2-2 {
  margin-left: 5px;
}

.radio3-1 {
  margin-left: 125px;
}

::v-deep .el-button {
  color: #fff;
  background-color: rgb(255, 176, 73);
  // border-color: rgb(212, 94, 78);
  border-radius: 0;
  border: 0;

  width: 130px;
  height: 68px;
  font-size: 28px;
  letter-spacing: 5px;
}

::v-deep .el-tabs__item {
  background: rgb(255, 176, 73);
  color: #fff;
  margin-bottom: 10px;
  text-align: center !important;
  width: 210px;
  height: 75px;
  font-size: 25px;
  letter-spacing: 2px;
  line-height: 75px;
  margin-right: 10px;
}

.el-tabs__header {
  width: 200px;
}
::v-deep .el-tabs__item.is-active {
  color: #fff;
  background: #790405;
  border: 1px solid rgb(255, 176, 73);
  text-align: center;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding: 0px 20px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:last-child {
  padding: 0px 20px 0px 20px;
}

::v-deep .el-tabs__nav-wrap::after {
  width: 0;
}
::v-deep .el-tabs__active-bar {
  width: 0;
  height: 0;
}
::v-deep .el-carousel--horizontal {
  margin-bottom: 2%;
}
.nameint {
  width: 300px;
  margin-right: 50px;
}
.nameinte {
  width: 74%;
  // margin-right: 50px;
}
.nametel {
  display: flex;
  justify-content: flex-start;
}
::v-deep .el-input__inner {
  box-shadow: 0px 0px 0px 0px rgb(255, 255, 255);
  height: 55px;
  font-size: 20px;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.butform {
  margin-left: 220px;
}
</style>
export function username(filed, value, cb) {
    // console.log(arguments);
    if (!/^\w{2,20}$/.test(value)) {
        return cb(new Error('2到20位数字字母'))
    }
    cb()
}
export function password(filed, value, cb) {
    // console.log(arguments);
    if (!/\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(value)) {
        // if (!/\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(value)) {
        return cb(new Error('包含数字字母'))
    }
    cb()
}
export function required(filed, value, cb) {
    if (value === undefined || /^\s*$/.test(value)) {
        return cb(new Error('请输入内容'))
    }
    cb()
}
export function telephone(filed, value, cb) {
    if (!/^1\d{10}$/.test(value)) {
        return cb(new Error('电话号码是以1开头的11位数字'))
    }
    cb()
}
export default { username, password, required, telephone }

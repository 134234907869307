<template>
  <div id="app">
    <h1 style="font-size: 0">安思医院约诊</h1>
    <div class="page-e">
      <!-- 轮播图 -->
      <el-carousel :interval="4000" height="440px" >
        <div v-for="item in newsListban" :key="item.id">
          <el-carousel-item v-if="item.columnName == '网站首页banner'">
            <img :src="item.picture" class="bannerHeight">
          </el-carousel-item>
        </div>

      </el-carousel>
      <!-- 轮播图结束 -->
      <div class="mainform" >
        <div class="block">
        <h4>若您有任何问题，意见或建议请与我们联系。我们将竭诚为您服务!</h4>
        

          <div style="margin-top:20px;">
            <el-radio-group class="group1" v-for="item in newsListtime" :key="item.id" v-model="radio2">
             <div @click="gettime(item.time)">
              <el-radio-button :label="item.time" >
                
                {{ item.workDate }}

              </el-radio-button>
             </div>
          
            </el-radio-group>
          </div>

          <div>
            <el-radio-group class="group2" v-for="docter in newsListtimedoc1" :key="docter.id" v-model="radio1">
              <el-radio-button :label="docter" >
                {{ docter.doctorName }}
                {{ docter.timeSlot }}

              </el-radio-button>
            
            </el-radio-group>
          </div>

        </div>

        <el-form ref="exam" :rules="rules" label-position="top" :model="form" label-width="50px">
          <el-form-item label="您的姓名" prop="name">
            <el-input size="small" v-model="form.name" ></el-input>
          </el-form-item>

          <el-form-item label="手机" prop="tel">
            <el-input v-model.number="form.tel" value=value.replace(/^[0-9]{11}$/g></el-input>
          </el-form-item>

          <el-form-item label="您要咨询的内容" prop="text">
            <el-input v-model="form.text"></el-input>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input type="email" v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item class="button1">
            <el-button type="primary" @click="onSubmit()">立即预约</el-button>
          </el-form-item>

        </el-form>

      </div>

    </div>
  </div>
</template>

<script>
import { telephone } from "../../tools";
import {
  webUserRegister,
  webNewsList,
  docwebList,
  createAppointment,
  webTimeList,
  docTimeList,
} from "@/service/home";

export default {
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      }
    };
    var checkText = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("不能为空"));
      }
    };
    var checkEmail = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error('不能为空'));
      // }
    };
    return {
      docters: [],
      // 单选框
      // doclabel:'',
      doc_obj: [],
      form: {
        birthdayName: "",
      },
      radio1: "",
      radio2: "",
      radio3: "",
      newsListban: [],
      newsListtime: [],
      newsListtimedoc: [],
      newsListtimedoc1: [],

      imgList: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],
      newsList: [],
      newstimeList: [],

      select: "",
      value: "",
      // 下拉框结束
      value1: "",
      form: {
        name: "",
        tel: "",
        text: "",
        content: "",
        email: "",
        age: "",
        dateTime: "",
      },
      rules: {
        tel: [{ validator: telephone, trigger: "blur" }],
        name: [{ validator: checkName, trigger: "blur" }],
        text: [{ validator: checkText, trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
      },
    };
  },
  computed: {
    changedate() {},
  },

  methods: {
    async gettime(msg) {
      const that = this;
      that.docters = [];

      try {
        let params = {
          time: msg,
        };
        const { data } = await docTimeList(params);
        this.newsListtimedoc1 = data;
      } catch (error) {}
    },

    selectTime(val) {
      const that = this;
      //每次添加数据前，进行数据清除   因为this绑定了数据，但是你数据没清除，所以他一直是在的
      //需要手庆c情一次
      that.docters = [];
      this.form.birthdayName = val;
      let listtime = this.newsListtime;
      listtime.forEach(function (item, index) {
        let date = new Date(item.workDate);
        let time = `${
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

        alert(time);

        if (val == time) {
          // 数据添加
          that.docters.push(item);
        }
      });
    },

    async ontime() {
      try {
        let params = {
          time: this.form.dateTime,
        };
        const { data } = await webTimeList(params);
        this.newstimeList = data;
      } catch (error) {}
      // },
    },
    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsListban = data;
        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort == 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort != 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }

        this.newsListjian.list = a4;

        // 置顶结束
      } catch (error) {}
    },

    async docwebList() {
      try {
        const { data } = await docwebList();
        this.newsListtime = data;

        if (this.newsListtime.length == 0) {
          alert("当前暂无排班医生");
          this.$router.push("/");
        }

        for (let i = 0; i < this.newsListtime.length; i++) {
          let b = this.newsListtime[i].workDate.split("T");
          this.newsListtime[i].workDate = b[0];
          let c = 0;
          for (let g = 0; g < this.newsListtime.length; g++) {
            if (
              this.newsListtime[i].workDate == this.newsListtime[g].workDate
            ) {
              c = c + 1;
            }
          }
          if (c >= 2) {
            this.newsListtime.splice(i, 1);
            i--;
          }
          c = 0;
        }
      } catch (error) {}
    },

    time(data) {},

    async onSubmit() {
      let a = /^1\d{10}$/;
      let b = false;
      if (a.test(this.form.tel)) {
        if (this.form.name == "") {
          alert("请输入您的姓名");
        } else if (this.form.text == "") {
          alert("请输入你要咨询的内容");
        } else if (this.form.email == "") {
          alert("请输入邮箱");
        } else if (this.radio1 == "") {
          alert("请选择需要预约的医生");
        } else {
          b = true;
        }
      }

      if (b) {
        // 注册
        try {
          const params = {
            // userRegister: {
            age: 0,
            city: "string",
            content: "string",
            createTime: this.radio1.workDate,
            id: 0,
            mailbox: this.form.email,
            mobile: this.form.tel,
            name: this.form.name,
            picture: "string",
            // }
          };
          const { data } = await webUserRegister(JSON.stringify(params));

          this.radio3 = data;
        } catch (error) {}

        // 预约

        try {
          const params = {
            // subscribe: {
            createTime: this.radio1.workDate,
            userName: this.form.name,
            mobile: this.form.tel,
            content: this.form.text,
            address: "string",
            department: "",
            doctorId: this.radio1.doctorId,
            doctorName: "string",
            feeDifference: 0,
            hospital: "string",
            id: 0,
            rosteringId: this.radio1.id,
            serviceCharge: 0,
            source: "官网",
            userId: this.radio3,
            // }
          };
          const { data } = await createAppointment(JSON.stringify(params));

          alert("预约成功");
        } catch (error) {}
      } else {
        alert("信息填写输入有误,请重新输入");
      }
    },
  },
  mounted() {
    // this.onSubmit();
    this.getNewsList();
    this.docwebList();
  },
};
</script>

<style lang="scss" scoped>
.bannerHeight {
  width: 100%;
}

::v-deep .el-main {
  min-height: 1500px;
}
#app {
  line-height: 0;
}

.page-d {
  line-height: 10px;
  text-align: left;
}

h4 {
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 2px;
  height: 8px;
}

.bg {
  background: center;
  margin-bottom: 80px;
}

.mainform {
  padding: 0px 5% 0px 5%;
  text-align: left;
}

::v-deep .el-form-item__label {
  font-size: 20px;
}

::v-deep .el-input__inner {
  height: 50px;
  width: 730px;
  margin-bottom: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 0px 0px 0px #e4e2e2;
  box-shadow: 0px 0px 0px 0px #e4e2e2;
  font-size: 30px;
}

.mainform {
  line-height: 0;
}

.el-input {
  width: 600px;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-button--primary {
  background: #a31010;
  width: 200px;
  border-radius: 8px;
  margin: 40px 0p 40px 0px;
}

.el-button--primary {
  margin: 30px 0px 30px 0px;
}

::v-deep .el-form-item__error {
  padding-top: 0px;
  margin-top: -4px;
  margin-left: 10px;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0px;
}

.el-form-item {
  margin-top: 10px;
}

.block {
  margin-top: 5%;
  margin-bottom: 2%;
}

::v-deep .el-carousel--horizontal {
  margin-bottom: 2%;
}

.demonstration {
  font-size: 12px;
  margin-bottom: 5%;
}

.date {
  margin-top: 2%;
  margin-bottom: 1%;
}

.group1 {
  margin-bottom: 1%;
  margin-right: 1%;
}
.group2 {
  margin-right: 1%;
}
</style>
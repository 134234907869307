<template>
  <div id="page-search">
    <div class="cardtwo">
      <el-input v-model="parameter" class="el-input1" placeholder="请输入内容">
        <el-button
          el-button
          slot="append"
          class="search"
          @click="gteParameterList"
          >搜索</el-button
        >
      </el-input>
    </div>

    <div class="more">
      <div
        class="cardthree"
        v-for="(item, index) in list"
        :key="index"
        @click="search(item.title)"
      >
        <div class="text">
          <div class="textimg">
            <img :src="item.picture" style="width: 100%" alt="" />
          </div>
          <div class="text1" @click="tonews(item.id)">
            <p class="p1">{{ item.title }}</p>
            <p class="p2">
              {{ item.abstracts }}
            </p>
            <el-link class="link1">More</el-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
  <script>
import { ParameterList } from "@/service/home";
export default {
  data() {
    return {
      //输入框的值
      parameter: "",
      //新闻列表
      list: [],
    };
  },
  mounted() {
    this.gteParameterList();
  },
  methods: {
    tonews(id) {
      console.log(123456);

      this.$router.push({
        path: "/news_pho/",
        query: {
          id: id,
        },
      });
    },
    //搜索加查询
    gteParameterList() {
      let c = sessionStorage.getItem("getIndexByManagement");

      if (this.parameter == "") {
        this.parameter = c;
      }
      ParameterList({ parameter: this.parameter }).then((res) => {
        // alert(this.parameter)
        this.list = res.data;
        console.log(this.list, "zxing");
      });
    },
    //点击下面新闻进行搜索
    search(item) {
      this.parameter = item;
      this.gteParameterList();
    },
  },
};
</script>
  <style lang="scss" scoped>
#app {
  line-height: 0;
}

.cardtwo {
  text-align: start;
  line-height: 0px;
  margin-top: 5%;
  padding: 0px 12% 0px 4%;
}
::v-deep .el-button {
  margin-right: -15%;
}

::v-deep .el-input__inner {
  height: 65px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
}
.el-input {
  // position: relative;
  font-size: 24px;
}
::v-deep .search {
  width: 250%;
  background: #a31010 !important;
  height: 65px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
  font-size: 28px;
  letter-spacing: 5px;
  color: #fff !important;
}
::v-deep .el-icon-search {
  color: #fff;
  font-size: 30px;
}

.cardthree {
  width: 100%;
  line-height: 20px;
  margin-bottom: 1.5%;
  margin-top: 1.5%;
  padding-bottom: 8px;
  border-bottom: 1px solid #cccccc;
}
.text1 {
  width: 74%;
  line-height: 100%;
  margin-left: 2%;
  padding-top: 1%;
}

.text {
  display: flex;
  justify-content: left;
}
.textimg {
  width: 42%;
}
.p1 {
  font-size: 38px;
  line-height: 40px;
  margin-bottom: 2%;
}
.p2 {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 2%;
  color: rgb(77, 77, 77);
}
.link1 {
  font-size: 18px;
}
.more {
  padding: 0px 4% 0px 4%;
  margin-top: 6%;
}
</style>
<template>
  <div id="page-doctor">
    <div class="bg">
      <div class="doc">
        <img
          src="../../../public/img/doctor/imgs/doctor.png"
          style="width: 30%"
          class="banner"
          alt=""
        />

        <el-tabs
          v-model="activeName"
          :tab-position="tabPosition"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="精神科" :name="1">
            <div class="imgs" v-for="item in newsList2" :key="item.id">
              <div class="imgs" v-if="item.sectionType == 1">
                <img
                  :src="item.picture"
                  style="width: 250px"
                  class="docer"
                  @click="tolink(item.id)"
                  alt=""
                />
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="心理科" :name="2">
            <div class="imgs" v-for="item in newsList4" :key="item.id">
              <div v-if="item.sectionType == 2">
                <img
                  :src="item.picture"
                  style="width: 250px"
                  class="docer"
                  @click="tolink2(item.id)"
                  alt=""
                />
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="中医科" :name="3">
            <div class="imgs" v-for="item in newsList5" :key="item.id">
              <div v-if="item.sectionType == 3">
                <img
                  :src="item.picture"
                  style="width: 250px"
                  class="docer"
                  @click="tolink3(item.id)"
                  alt=""
                />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { webDoctorList, webDoctor } from "@/service/home";

export default {
  data() {
    return {
      newsList2: [],
      newsList4: [],
      newsList5: [],
      newsList3: [],
      tabPosition: "left",
      activeName: 2,
      list: [
        { id: 1, src: require("../../../public/img/doctor/doctors/刘军.png") },
        { id: 2, src: require("../../../public/img/doctor/doctors/史杰.png") },
        { id: 3, src: require("../../../public/img/doctor/doctors/周方.png") },
        { id: 4, src: require("../../../public/img/doctor/doctors/姜涛.png") },
        {
          id: 5,
          src: require("../../../public/img/doctor/doctors/崔思鹏.png"),
        },
        {
          id: 6,
          src: require("../../../public/img/doctor/doctors/梁月竹.png"),
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.getwebDoctor();
    },

    async getwebDoctor() {
      try {
        let params = {
          sectionType: this.activeName,
        };
        console.log("11111走了");
        const { data } = await webDoctor(params);
        console.log(data, "22222走了");
        this.newsList2 = data;
        this.newsList4 = data;
        this.newsList5 = data;
      } catch (error) {
        console.log(error, "33333走了");
      }
    },
    async getwebDoctorList() {
      try {
        const { data } = await webDoctorList();
        this.newsList3 = data;

        console.log(newsList3, 99999);
      } catch (error) {}
    },
    tolink(id) {
      this.$router.push({
        path: "/doctors_pho/",
        query: {
          id: id,
        },
      });
    },
    tolink2(id) {
      this.$router.push({
        path: "/doctors-2_pho/",
        query: {
          id: id,
        },
      });
    },
    tolink3(id) {
      this.$router.push({
        path: "/doctors-3_pho/",
        query: {
          id: id,
        },
      });
    },
    tolinkdoc() {
      console.log("aaa");
    },
  },
  mounted() {
    this.getwebDoctorList();
    this.getwebDoctor();
    this.testQuery = this.$route.query.testQuery;
    console.log(this.testQuery, "00");
  },
};
</script>

<style lang="scss" scoped>
#app {
  line-height: 0;
}
.bgimg {
  background: center;
  margin-bottom: 40px;
}

.bg {
  background: rgb(242, 242, 242);
  // margin: 0px 15% 0px 15%;
}

.banner {
  margin-left: 1%;
  margin-top: 1%;
}
.doc {
  margin-top: 3%;
  // margin-bottom: 5%;
}
.docer {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.imgs {
  display: inline-block;
}
.imgs img {
  margin-right: 4rem;
  margin-bottom: 2rem;
}

//标签页
::v-deep .el-tabs__header {
  background: #fff;
  padding: 2% 2% 2% 2%;
  margin-left: 1%;
}
::v-deep .el-tabs__item.is-active {
  background: red;
  color: #fff;
}
::v-deep .el-tabs__nav {
  text-align: center;
}
::v-deep .el-tabs__content {
  height: 1570px;
}
::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background: #790405;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
}
::v-deep .el-tabs__item.is-active {
  color: #fff;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header {
  border: 0;
  width: 250px;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  text-align: center;
}
::v-deep .el-tabs__item {
  padding: 0;
  margin: 5% 5%;
  width: 142px;
  height: 30px;
  line-height: 30px;
}
::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  text-align: center;
  font-size: 15px;
}
::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  border: 0;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
}
</style>

<template>
  <div id="a">
    <h1 style="font-size: 0">安思医院诊疗项目</h1>
    <el-main>
      <!-- 轮播图 -->
      <el-carousel :interval="4000" height="440px">
        <div v-for="item in newsListban" :key="item.id">
          <el-carousel-item v-if="item.columnName == '网站首页banner'">
            <img :src="item.picture" class="bannerHeight" />
          </el-carousel-item>
        </div>
      </el-carousel>
      <!-- 轮播图结束 -->

      <!-- 卡片 -->

      <div
        class="cardone"
        v-for="menu in newsList"
        :key="menu.id"
        @click="tonews(menu.id)"
      >
        <div class="card1" v-if="newsList.length">
          <img
            style="width: 90%"
            v-if="newsList.length"
            :src="menu.picture"
            alt=""
          />
        </div>

        <div class="maxcard">
          <p>{{ menu.title }}</p>

          <el-popover placement="right" width="400" trigger="hover">
            <span style="white-space: pre-wrap" v-html="menu.abstracts"> </span>
            <el-button slot="reference" class="popover">
              <span style="white-space: pre-wrap" v-html="menu.abstracts">
              </span>
            </el-button>
          </el-popover>
        </div>
      </div>
    </el-main>
  </div>
</template>
  
  <script>
import { webProjectList, webNewsList } from "@/service/home";

export default {
  metaInfo: {
    title: "诊疗项目", // set a title
    meta: [
      // set meta
      {
        name: "keyWords",
        content: "诊疗项目",
      },
      {
        name: "description",
        content: "诊疗项目",
      },
    ],
  },
  name: "index",
  data() {
    return {
      newsListban: [],
      title: "",

      imgList: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],
      input: "",
      newsList: [],
      bannerHeight: "",
      activeIndex: "1",
      activeIndex2: "1",
    };
  },

  methods: {
    tonews(id) {
      this.$router.push({
        path: "/news/",
        query: {
          id: id,
        },
      });
    },

    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsListban = data;

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListban) {
          if (this.newsListban[i].sort == 1) {
            a4[qqc] = this.newsListban[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListban) {
          if (this.newsListban[i].sort != 1) {
            a4[qqc] = this.newsListban[i];
            qqc = qqc + 1;
          }
        }

        this.newsListban = a4;

        // 置顶结束
      } catch (error) {}
    },

    async getwebProjectList() {
      try {
        const { data } = await webProjectList();
        this.newsList = data;

        // 置顶
        let qqc = 0;
        for (let i in this.newsList) {
          if (this.newsList[i].sort == 1) {
            let lk = this.newsList[qqc];
            this.newsList[qqc] = this.newsList[i];
            this.newsList[i] = lk;
            qqc = qqc + 1;
          }
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getwebProjectList();
    this.getNewsList();
  },
};
</script>
  
  <style lang="scss" scoped>
.bannerHeight {
  width: 100%;
}

#b > ul {
  height: 60px;
}

.logoimg {
  height: 60px;
  opacity: 0;
}

.logoimg {
  height: 60px;
}
.el-menu {
  display: flex;
  justify-content: space-between;
}
.el-menu > img {
  background: center;
  width: 15%;
  padding-right: 50px;
}
::v-deep .el-header,
.el-footer {
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  padding: 0px 12% 0px 12%;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

::v-deep .el-main {
  // background-color: #E9EEF3;
  color: #333;
  // text-align: center;
  // line-height: 160px;
  // padding: 0px 100px 0px 100px
  padding: 0px 0px 0px 0px;
  background: #ffffff;
  line-height: 60px;
}
//   .el-menu .el-menu-item.is-active{
//   background-color: #1518d7;
// }
.el-menu-item.is-active {
  background-color: rgb(181, 12, 12) !important;
  border-bottom-color: rgb(181, 12, 12) !important;
}
.el-footer {
  background: rgb(121, 4, 5);
}

.card1 {
  line-height: 15px;
  text-align: left;
  width: 380px;
}

.card1 p {
  font-weight: bold;
  font-size: 14px;
  word-spacing: 10px;
  margin: 10px 0px 10px 0px;
  display: inline-block;
}
.card1 > p {
  margin: 10px 0px 10px 0px;
}
.card1 > span {
  font-size: 10px;
}

.cardone {
  display: flex;
  justify-content: center;
  padding: 0px 10% 0px 10%;
  margin-bottom: 20px;
}
.cardone ul {
  list-style: none;
  padding: 0;
  margin: 20px 0px 0px 0px;
}
.cardone li {
  margin-top: 10px;
}
.card-span2 {
  padding-bottom: 10px;
  font-size: 10px;
}
.el-link {
  font-size: 12px;
  margin-top: 10px;
  color: #a31010;
  font-weight: bold;
}

.title {
  // text-align: center !important;
  line-height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.time {
  display: flex;
  // white-space: wrap;
  flex-wrap: wrap;
  // justify-content: space-between;
}
.date {
  font-weight: bold;
  font-size: 18px !important;
  padding-left: 15px;
}
.year {
  font-size: 10px;
}
.news {
  padding-left: 50px;
  font-size: 10px;
}

.cardone {
  margin-bottom: 40px;
}

// 底部
.footer {
  text-align: left;
}
::v-deep .el-footer {
  line-height: 30px !important;
  height: 200px !important;
}

ul {
  padding: 0;
}
.el-footer ul {
  margin: 0;
  color: #fff;
  font-size: 12px;
}
.el-footer li {
  margin: 0;
}
.footer > .nav > p {
  padding-top: 20px;
  font-size: 14px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul > li {
  font-size: 8px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul {
  float: left;
  //  margin-right: 30px;
}
.footer ul li img {
  width: 100%;
}

.footer > .nav > p:nth-child(1) {
  margin-right: 120px;
}
.footer > .nav > p:nth-child(2) {
  margin-right: 100px;
}
.footer > .nav > p:nth-child(3) {
  margin-right: 90px;
}

.footer > ul:nth-child(2) {
  margin-right: 20px;
}
.footer > ul:nth-child(3) {
  margin-right: 60px;
}
.footer > ul:nth-child(4) {
  margin-right: 135px;
}
.footer > ul:nth-child(5) {
  margin-right: 120px;
}
.footer > ul:nth-child(6) {
  margin-right: 45px;
}
.footer > ul:nth-child(7) {
  width: 60px;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
}
.end {
  background: rgb(121, 4, 15);
  height: 60px;
  line-height: 60px;
}
.end > p {
  margin-left: 450px;
  font-weight: lighter;
  // padding-top: 20px;
  font-size: 8px;
  color: rgb(255, 255, 255);
  // letter-spacing: 1px;
}
.end > p span {
  margin-left: 40px;
}

// b页面的样式
.el-main > img {
  background: center;
  margin-bottom: 20px;
}
.card1 > img {
  border-radius: 20px;
  // box-shadow: 1px 2px 10px 2px rgb(152, 151, 151);
}

.maxcard {
  margin-top: 2%;
  padding-left: 40px;
  line-height: 15px;
  width: 450px;
  height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  // display: flex;
  // align-items:center
}
.maxcard > p {
  font-size: 24px;
  margin-bottom: 2%;
  line-height: 150%;
}
.maxcard > span {
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 1px;
}
::v-deep .el-carousel--horizontal {
  margin-bottom: 2%;
}
.popover {
  border: 0px;
  text-align: left;
  line-height: 150%;
}
</style>
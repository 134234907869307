<template>
  <div class="page-c">
    <h1 style="font-size: 0">安思医院医学科普</h1>
    <!-- 轮播图 -->
    <el-carousel :interval="4000" height="440px">
      <div v-for="item in newsListban" :key="item.id">
        <el-carousel-item v-if="item.columnName == '网站首页banner'">
          <img :src="item.picture" class="bannerHeight" />
        </el-carousel-item>
      </div>
    </el-carousel>
    <!-- 轮播图结束 -->

    <div class="content">
      <p>健康科普</p>

      <ul>
        <li
          v-for="item in newsListjian.list"
          :key="item.id"
          @click="tonews(item.id)"
          style="cursor: pointer"
        >
          <div class="wrap">
            <div class="font">
              <p class="line3">
                <span class="num">{{ item.createTime }}</span>
                <span class="line"></span> <br />
                <span class="line2">{{ item.title }}</span>
              </p>

              <div class="aaa">
                <img
                  class="jio"
                  src="../../../public/img/c/jio.png"
                  width="60px"
                  height="30px"
                  alt=""
                />

                <span class="span2" style="display: block; text-indent: 2em">
                  {{ item.abstracts }}
                </span>
              </div>
            </div>
            <img class="text1" :src="item.picture" alt="" />
          </div>
        </li>
      </ul>
      <div class="pagination">
        <el-row style="height: 60px; margin-right: 1px">
          <el-pagination
            style="float: right; margin-top: 10px"
            background
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { webNewsList, webjianList } from "@/service/home";

export default {
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      newsListban: [],
      newsListjian: [],
      newsList: [],
      imgLists: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],
    };
  },
  methods: {
    tonews(id) {
      this.$router.push({
        path: "/news_pho/",
        query: {
          id: id,
        },
      });
    },

    handleCurrentChange() {
      this.getwebjianList();
    },

    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsListban = data;

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListban) {
          if (this.newsListban[i].sort == 1) {
            a4[qqc] = this.newsListban[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListban) {
          if (this.newsListban[i].sort != 1) {
            a4[qqc] = this.newsListban[i];
            qqc = qqc + 1;
          }
        }

        this.newsListban = a4;

        // 置顶结束
      } catch (error) {}
    },

    async getwebjianList() {
      try {
        const { data } = await webjianList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        });
        this.newsListjian = data;

        for (let i = 0; i < this.newsListjian.list.length; i++) {
          let b = this.newsListjian.list[i].createTime.split("T");
          let d = b[1].split("."); // this.newsListjian.list[i].createTime = b[0] + " " + d[0]
          this.newsListjian.list[i].createTime = b[0];
        }

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort == 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsListjian.list) {
          if (this.newsListjian.list[i].sort != 1) {
            a4[qqc] = this.newsListjian.list[i];
            qqc = qqc + 1;
          }
        }

        this.newsListjian.list = a4;

        // 置顶结束
      } catch (error) {}
    },
  },
  mounted() {
    this.getNewsList();
    this.getwebjianList();
  },
};
</script>
  
  <style lang="scss" scoped>
.bannerHeight {
  width: 100%;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  margin-top: 20px;
}
.bg {
  background: center;
  margin-bottom: 20px;
}
.el-main {
  line-height: 0px !important;
  text-align: left !important;
}
.content {
  padding: 0% 5% 0px 5%;
}
.wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
  margin-top: 55px;
}
.content > p {
  font-weight: bolder;
  color: rgb(171, 31, 31);
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 80px;
}

.num {
  color: rgb(202, 202, 202);
  font-size: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  transform: translate(17rem, 3.5rem);
}
.line {
  margin-right: 10px;
  margin-bottom: 5px;
  display: inline-block;
  width: 30px;
  height: 1px;
  background: #000;
  margin-bottom: 12px;
}
.line2 {
  font-size: 38px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 575px;
  height: 23px;
  display: inline-block;
  padding-top: 24px;
  margin-top: 20px;
}
.line3 {
  margin-bottom: 30px;
}

.font {
  padding-top: 10px;
  // transition: all 0.5s linear 0s
}
.aaa {
  display: flex;
  justify-content: flex-start;
}
.jio {
  margin-top: 10px;
  opacity: 0;
}

// .font:hover {
//   // width: 90%;

// }

.font:hover .jio {
  opacity: 1;
  transition: all 0.5s;
}
.font:hover .num {
  color: #000;
  transition: all 0.5s;
}
.font:hover .line {
  // width: 90%;
  // transform:scale(2);
  width: 50px;
  transition: all 0.5s;
}
.font:hover .line2 {
  // width: 90%;
  margin-left: 20px;
  transition: all 0.5s;
}
.font:hover .span2 {
  // width: 90%;
  margin-left: 95px;
  // transform:translate(20px,0px);
  transition: all 0.5s;
}
.span2 {
  font-size: 26px;
  line-height: 36px;
  margin-right: 20px;
  letter-spacing: 4px;
}
.text1 {
  height: 280px;
  width: 280px;
  margin-top: 80px;
}
.pagination {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ba141c;
  color: #fff;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #fff;
  color: #ba141c;
  min-width: 30px;
  border: 1px solid #ba141c;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(121, 4, 5);
  color: #fff;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  margin: 0 5px;
  background-color: #fff;
  color: #606266;
  min-width: 30px;
  border: 1px solid rgb(121, 4, 5);
  border-radius: 0px;
}
</style>f
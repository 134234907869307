<template>
  <div id="a" v-if="newsList2.length">
    <h1 style="font-size: 0">安思医院官网</h1>
    <el-main v-if="newsList.length">
      <!-- 轮播图 -->
      <el-carousel :interval="4000" height="440px">
        <div v-for="item in newsList" :key="item.id">
          <el-carousel-item v-if="item.columnName == '网站首页banner'">
            <!-- <div v-if="item.sort == 1"> -->
            <img :src="item.picture" class="bannerHeight" />
            <!-- </div> -->
            <!-- <div v-if="item.sort != 1">
                      <img  :src="item.picture" class="bannerHeight" />
                   </div> -->
          </el-carousel-item>
        </div>
      </el-carousel>
      <!-- 轮播图结束 -->

      <!-- 卡片 -->
      <div class="cardone">
        <div class="card1">
          <!-- <img src="../../../public/img/ansi.png" class="ansi" alt=""> -->
          <div class="firnew">
            <p class="pfirnew1">安思</p>
            <div class="firnew2">
              <p class="pfirnew2">要闻</p>
              <p class="pfirnew3">COMPANYDYNAMICS</p>
            </div>
          </div>

          <!-- 安思新闻 -->

          <div>
            <div @click="tonews(arr.id)" style="cursor: pointer">
              <div class="asimg">
                <img :src="arr.picture" class="mimg" alt="" />
              </div>

              <div class="card2">
                <span class="pspan">{{ arr.title }}</span>
              </div>

              <div class="card3">
                <span>
                  {{ arr.abstracts }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="maxcard">
          <div>
            <div class="card1">
              <!-- <img src="../../../public/img/gs.png" class="ansi" alt=""> -->
              <div class="firnew">
                <p class="pfirnew1">诊所</p>
                <div class="firnew2">
                  <p class="pfirnew2">新闻</p>
                  <p class="pfirnew3">COMPANYDYNAMICS</p>
                </div>
              </div>

              <!-- 公司新闻 -->

              <div @click="togsnews2(arr2[0].id)" style="cursor: pointer">
                <p class="titp">{{ this.arr2[0].title }}</p>
                <br />
                <div class="cardspan">
                  <el-popover placement="right" width="400" trigger="hover">
                    <span class="card-span2">{{ this.arr2[0].abstracts }}</span>
                    <el-button slot="reference" class="popover">
                      <span class="card-span2">{{
                        this.arr2[0].abstracts
                      }}</span>
                    </el-button>
                  </el-popover>
                </div>
                <span class="xiangqing">查看详情></span>
              </div>

              <!-- <div> -->
              <!-- </div> -->

              <div v-for="(item, index) in arr2" :key="item.id">
                <div
                  v-if="index >= 1"
                  @click="togsnews(item.id)"
                  style="cursor: pointer"
                >
                  <div
                    v-if="index <= 4"
                    @click="togsnews(item.id)"
                    style="cursor: pointer"
                  >
                    <div class="card2">
                      <ul>
                        <li>
                          <div class="title">
                            <span class="time date">{{ item.ri }}</span>
                            <span class="time year">{{ item.yearYue }}</span>
                          </div>
                          <span class="news">{{ item.title }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-button class="but" type="primary" size="small" @click="tosearch"
            >更多新闻 》</el-button
          >
          <!--  -->
        </div>
      </div>

      <!-- 搜索框 -->
      <div class="cardtwo">
        <el-input
          v-model="queryInfo.query"
          class="el-input1"
          placeholder="请输入内容"
        >
          <el-button
            slot="append"
            class="search"
            icon="el-icon-search"
            @click="tosearch"
          ></el-button>
        </el-input>
      </div>

      <!-- 标题 -->
      <div class="server">
        <p>
          我们的服务 <br />
          <span>OUR TEAM INTRODUCTION</span>
        </p>

        <!-- <img src="../../../public/img/server.png" class="serverimg" alt=""> -->
        <div class="icons">
          <div class="iconss" @click="tomenub" style="cursor: pointer">
            <img
              src="../../../public/img/a/icon.png"
              class="servericon"
              style="width: 40%"
              alt=""
            />

            <p>诊疗项目</p>
          </div>

          <span class="spanicon"></span>
          <div class="iconss" @click="tomenuc" style="cursor: pointer">
            <img
              src="../../../public/img/a/icon3.png"
              class="servericon"
              style="width: 40%"
              alt=""
            />

            <p>医学科普</p>
          </div>
          <span class="spanicon"></span>
          <div class="iconss" @click="tomenud" style="cursor: pointer">
            <img
              src="../../../public/img/a/icon2.png"
              class="servericon"
              style="width: 40%"
              alt=""
            />

            <p>在线测评</p>
          </div>
          <span class="spanicon"></span>
          <div class="iconss" @click="tomenue" style="cursor: pointer">
            <img
              src="../../../public/img/a/icon1.png"
              class="servericon"
              style="width: 40%"
              alt=""
            />

            <p>在线约诊</p>
          </div>
          <span class="spanicon"></span>
          <div class="iconss" @click="tomenuf" style="cursor: pointer">
            <img
              src="../../../public/img/a/icon4.png"
              class="servericon"
              style="width: 40%"
              alt=""
            />

            <p>关于我们</p>
          </div>
        </div>
      </div>

      <!-- 三个图片轮播 -->
      <div class="three">
        <!-- <img src="../../../public/img/three.png" class="serverimg" alt=""> -->
        <div class="threeimg">
          <el-carousel type="card" :interval="4000" height="250px">
            <div v-for="item in newsList" :key="item.id">
              <el-carousel-item v-if="item.columnName == '首页-720展示'">
                <a
                  target="_blank"
                  href="https://www.720yun.com/t/762jv7mktv8?scene_id=21054657"
                >
                  <img :src="item.picture" />
                </a>
              </el-carousel-item>
            </div>
          </el-carousel>
        </div>
      </div>

      <!-- 标题2 -->
      <div class="server1">
        <p class="doc">
          我们的专家团队 <br />
          <span>Our Expert Team</span>
        </p>

        <div class="carouselBox">
          <el-carousel
            :loop="true"
            indicator-position="outside"
            :autoplay="false"
            class="carousel zhanshi"
          >
            <el-carousel-item
              class="el-car-item"
              v-for="(list, index) in newsList3"
              :key="index"
            >
              <div style="display: flex" class="divSrc">
                <div style="width: 25%" v-for="item in list" :key="item.id">
                  <img
                    width="380"
                    height="134"
                    class="img1"
                    :src="item.picture"
                    @click="tolink(item.id)"
                  />

                  <div class="title">{{ item.name }}</div>
                  <div class="textdoc">{{ item.affiliatedHospital }}</div>
                  <div class="textdoc">{{ item.professional }}</div>
                  <div class="docdoc">
                    <el-popover placement="top" width="400" trigger="hover">
                      <div class="textdoc">擅长：{{ item.advantage }}</div>
                      <el-button class="docbut" slot="reference">
                        <div style="color: rgb(100, 100, 100)">
                          <div class="shanchang" v-if="!item.advantage == ''">
                            擅长：{{ item.advantage }}
                          </div>
                        </div>
                      </el-button>
                    </el-popover>
                  </div>
                  <!-- <div class="textdoc2">{{item.mobile}}</div> -->
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import { webNewsList, ParameterList, webDoctorList } from "@/service/home";

export default {
  metaInfo: {
    title: "安思京顺官网", // set a title
    meta: [
      // set meta
      {
        name: "keyWords",
        content: "安思京顺官网",
      },
      {
        name: "description",
        content: "安思京顺官网",
      },
    ],
  },
  name: "index",
  data() {
    return {
      arr: [],
      arr2: [],
      input: "",
      bannerHeight: "",
      dataList: [],
      activeIndex: "1",
      activeIndex2: "1",
      newsList: [],
      newsList2: [],
      newsList3: [],
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 2,
      },
      imgList: [
        { id: 0, idView: require("../../../public/img/bg1.png") },
        { id: 1, idView: require("../../../public/img/bg1.png") },
        { id: 2, idView: require("../../../public/img/bg1.png") },
        { id: 3, idView: require("../../../public/img/bg1.png") },
      ],

      imgList2: [
        { id: 5, idView: require("../../../public/img/three1.png") },
        { id: 6, idView: require("../../../public/img/three1.png") },
        { id: 7, idView: require("../../../public/img/three1.png") },
      ],
    };
  },

  methods: {
    async getUserList() {},

    async getNewsList() {
      try {
        const { data } = await webNewsList();
        this.newsList = data;

        // 置顶
        let qqc = 0;
        let qqc1 = 0;
        let a2 = [];
        let a3 = [];
        let a4 = [];

        for (let i in this.newsList) {
          if (this.newsList[i].sort == 1) {
            a4[qqc] = this.newsList[i];
            qqc = qqc + 1;
          }
        }
        for (let i in this.newsList) {
          if (this.newsList[i].sort != 1) {
            a4[qqc] = this.newsList[i];
            qqc = qqc + 1;
          }
        }

        this.newsList = a4;

        // 置顶结束
      } catch (error) {}
    },
    async getParameterList() {
      // try {
      const { data } = await ParameterList({ parameter: "" });
      // const { data } = await ParameterList()
      this.newsList2 = data;

      // 置顶
      let qqc = 0;
      let qqc1 = 0;
      let a2 = [];
      let a3 = [];
      let a4 = [];
      for (let i in this.newsList2) {
        if (this.newsList2[i].sort == 1) {
          a4[qqc] = this.newsList2[i];
          qqc = qqc + 1;
        }
      }
      for (let i in this.newsList2) {
        if (this.newsList2[i].sort != 1) {
          a4[qqc] = this.newsList2[i];
          qqc = qqc + 1;
        }
      }

      this.newsList2 = a4;

      // 置顶结束

      for (let i = 0; i < this.newsList2.length; i++) {
        if (this.newsList2[i].columnName == "安思") {
          this.arr = this.newsList2[i];
          break;
        }
      }

      for (let i = 0; i < this.newsList2.length; i++) {
        if (this.newsList2[i].columnName == "安思") {
          this.arr2 = this.newsList2.splice(i, 1);
          i--;
        }
      }
      this.arr2 = this.newsList2;
    },
    async getwebDoctorList() {
      try {
        const { data } = await webDoctorList();

        let a = [];
        let number = 0;
        let sliceData = [];

        while (true) {
          sliceData = data.slice(number, number + 4);

          if (sliceData.length > 0) {
            a.push(sliceData);
            number += 4;
          } else {
            break;
          }
        }
        this.newsList3 = a;
      } catch (error) {}
    },

    tonews(id) {
      this.$router.push({
        path: "/news/",
        query: {
          id: id,
        },
      });
    },

    togsnews(id) {
      this.$router.push({
        path: "/news/",
        query: {
          id: id,
        },
      });
    },

    togsnews2(id) {
      this.$router.push({
        path: "/news/",
        query: {
          id: id,
        },
      });
    },

    tolink(id) {
      this.$router.push({
        path: "/doctors/",
        query: {
          id: id,
        },
      });
    },

    tosearch() {
      sessionStorage.setItem("getIndexByManagement", this.queryInfo.query);
      this.$router.push("/search");
    },

    tomenub() {
      this.$router.push("/medical");
    },
    tomenuc() {
      this.$router.push("/publicize");
    },
    tomenud() {
      this.$router.push("/assess");
    },
    tomenue() {
      this.$router.push("/bespeak");
    },
    tomenuf() {
      this.$router.push("/contact");
    },

    byEvents() {
      this.dataList = [
        {
          img: require("../../../public/img/doctor/doctors/史杰.png"),
          title: "史杰",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "shijie",
        },
        {
          img: require("../../../public/img/doctor/doctors/周方.png"),
          title: "周方",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "zhoufang",
        },
        {
          img: require("../../../public/img/doctor/doctors/史杰.png"),
          title: "史杰",
          textdoc: "首都医科大学",
          textdoc2: "xxx",
          id: "shijie",
        },
        {
          img: require("../../../public/img/doctor/doctors/周方.png"),
          title: "周方",
          textdoc: "首都医科大学",
          textdoc2: "xxxx",
          id: "zhoufang",
        },
        {
          img: require("../../../public/img/doctor/doctors/史杰.png"),
          title: "史杰",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "shijie",
        },
        {
          img: require("../../../public/img/doctor/doctors/周方.png"),
          title: "史杰",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "zhoufang",
        },
        {
          img: require("../../../public/img/doctor/doctors/史杰.png"),
          title: "周方",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "shijie",
        },

        {
          img: require("../../../public/img/doctor/doctors/周方.png"),
          title: "史杰",
          textdoc: "首都医科大学",
          textdoc2: "xxxxx",
          id: "zhoufang",
        },
      ];

      let newDataList = [];
      let current = 0;
      if (this.dataList && this.dataList.length > 0) {
        for (let i = 0; i <= this.dataList.length - 1; i++) {
          if (i % 4 !== 0 || i === 0) {
            //数据处理成几张展示
            if (!newDataList[current]) {
              newDataList.push([this.dataList[i]]);
            } else {
              newDataList[current].push(this.dataList[i]);
            }
          } else {
            current++;
            newDataList.push([this.dataList[i]]);
          }
        }
      }
      this.dataList = [...newDataList];
    },
  },
  mounted() {
    this.byEvents();

    this.getNewsList();
    this.getParameterList();
    this.getwebDoctorList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .zhanshi {
  height: 340px !important;
  width: 130% !important;
}
el-carousel__item el-car-item is-active is-animating {
  width: 100%;
}

.logoimg {
  height: 60px;
}
.el-menu {
  display: flex;
  justify-content: space-between;
}
.el-menu > img {
  background: center;
  width: 15%;
  padding-right: 50px;
}
::v-deep .el-header,
.el-footer {
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  padding: 0px 20% 0px 20%;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

::v-deep .el-main {
  // background-color: #E9EEF3;
  color: #333;
  // text-align: center;
  // line-height: 160px;
  // padding: 0px 100px 0px 100px
  padding: 0px 0px 0px 0px;
  background: #ffffff;
}

.el-menu-item.is-active {
  background-color: rgb(181, 12, 12) !important;
  border-bottom-color: rgb(181, 12, 12) !important;
}

.bannerHeight {
  //height: 460px;
  width: 100%;
}

.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

// 卡片样式

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}
.but {
  width: 100px;
  height: 35px;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

::v-deep .el-row {
  display: flex;
  justify-content: space-between;
}

.card1 {
  line-height: 15px;
  text-align: left;
  width: 380px;
}
.maxcard {
  margin-left: 30px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card1 p {
  font-weight: bold;
  font-size: 14px;
  word-spacing: 10px;
  margin: 10px 0px 10px 0px;
  display: inline-block;
}
.titp {
  margin: 5px 0px 2px 0px !important;
}
.card1 > p {
  margin: 10px 0px 10px 0px;
}
.card1 > span {
  font-size: 10px;
}
.card3 > span {
  font-size: 10px;
}
.card2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.pspan {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.card3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
}
.ansi {
  margin: 40px 0px 0px 0px;
  width: 50%;
}
.mimg {
  // margin: 10px 0px 10px 0px;
  margin-top: 8px;
  width: 100%;
  // height: 100%;
}
.asimg {
  width: 100%;
  // height: 330px;
  margin-bottom: 4%;
}
.cardone {
  display: flex;
  justify-content: center;
  padding: 0px 5% 0px 10%;
}
.cardone ul {
  list-style: none;
  padding: 0;
  margin: 5px 0px 0px 0px;
}
.cardone li {
  margin-top: 10px;
}
.card-span2 {
  padding-bottom: 10px;
  font-size: 10px;
}
.el-link {
  font-size: 12px;
  margin-top: 10px;
  color: #a31010;
  font-weight: bold;
}

.title {
  // text-align: center !important;
  line-height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.time {
  display: flex;
  // white-space: wrap;
  flex-wrap: wrap;
  // justify-content: space-between;
}
.date {
  font-weight: bold;
  font-size: 18px !important;
  padding-left: 15px;
}
.year {
  font-size: 10px;
}
.news {
  padding-left: 20px;
  font-size: 10px;
}
.el-button {
  margin: 10px 0px 0px -40px;
  background-color: #790405;
  border-color: #790405;
  border-radius: 0%;
}
.cardone {
  margin-bottom: 40px;
}
.cardtwo {
  padding: 0px 20% 0px 20%;
  text-align: start;
  line-height: 0px;
  margin-top: 4%;
}

::v-deep.el-input__inner {
  height: 200px !important;
}
::v-deep .el-input__inner {
  height: 60px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
}
::v-deep .search {
  width: 200px;
  background: #a31010 !important;
  height: 60px;
  box-shadow: 1px 0px 10px 1px rgb(228, 226, 226);
}
::v-deep .el-icon-search {
  color: #fff;
  font-size: 30px;
}

// 标题
.server {
  margin-top: 4%;
  margin-bottom: 20px;
  line-height: 20px;
  color: #790405;
  text-align: center;
}
.server1 {
  line-height: 20px;
  // padding: 30px 200px 0px 200px;
  padding: 0px 15% 0px 15%;
  color: #790405;
  text-align: center;
  // width: 100%;
}

.doc {
  margin-bottom: 5%;
}
.server > p {
  font-weight: bold;
  font-size: 18px;
}
.server > p > span {
  font-weight: lighter;
  font-size: 12px;
}
.server1 > p {
  font-weight: bold;
  font-size: 18px;
}
.server1 > p > span {
  font-weight: lighter;
  font-size: 12px;
}
.serverimg {
  width: 100%;
  padding: 20px 0px 50px 0px;
}

// 标题2
.server1 > ul {
  display: flex;
  flex-wrap: nowrap;
}
.server1 > ul > li > p {
  font-weight: bold;
  font-size: 18px;
}
.server1 > ul > li > span {
  font-size: 14px;
  // padding: 20p 0px 20px 0px;
}
.server1 > ul > li > img {
  width: 100%;
}

// 仨个图
.three {
  padding: 0px 10% 0px 10%;
  height: 280px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.threeimg {
  width: 80%;
}

// 底部
.footer {
  text-align: left;
  width: 80%;
}
::v-deep .el-footer {
  padding: 0px 10% 0px 10% !important;
  margin-top: 1000px;
  background: rgb(121, 4, 5);
  line-height: 30px !important;
  height: 200px !important;
  display: flex;
  justify-content: center;
}
.el-footer ul {
  margin: 0;
  color: #fff;
  font-size: 12px;
}
.el-footer li {
  margin: 0;
}
.footer > .nav > p {
  padding-top: 20px;
  font-size: 14px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul > li {
  font-size: 8px;
  color: #fff;
  // margin-right: 20px;
}
.footer > ul {
  float: left;
  //  margin-right: 30px;
}
.footer ul li img {
  width: 100%;
}

.footer > .nav > p:nth-child(1) {
  margin-right: 10%;
}
.footer > .nav > p:nth-child(2) {
  margin-right: 12%;
}
.footer > .nav > p:nth-child(3) {
  margin-right: 12%;
}

.footer > ul:nth-child(2) {
  margin-right: 8% !important;
}
.footer > ul:nth-child(3) {
  margin-right: 8%;
}
.footer > ul:nth-child(4) {
  margin-right: 10%;
}
.footer > ul:nth-child(5) {
  margin-right: 10%x;
}
.footer > ul:nth-child(6) {
  margin-right: 4%;
}
.footer > ul:nth-child(7) {
  width: 60px;
}

//   .footer > ul > li {
//     list-style: none;

// display: inline-block;
//   }
.nav {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.end {
  background: rgb(121, 4, 15);
  height: 60px;
  line-height: 60px;
}
.end > p {
  margin-left: 450px;
  font-weight: lighter;
  // padding-top: 20px;
  font-size: 8px;
  color: rgb(255, 255, 255);
  // letter-spacing: 1px;
}
.end > p span {
  margin-left: 40px;
}
::v-deep .el-button {
  margin: 0px 0px 0px 0px !important;
}
::v-deep .el-button[data-v-1c8aa2cd] {
  margin: 0px 0px 0px 0px !important;
  background-color: #790405;
  border-color: #790405;
  border-radius: 0;
}
::v-deep .el-input-group__append,
.el-input-group__prepend {
  padding: 0;
}

.carouselBox .carousel .el-car-item[data-v-8f0ec2ee] {
  display: -webkit-box;
  // height: 100%;
  //justify-content: center;
}

::v-deep .carouselBox .carousel {
  width: 100%;
  // height: 100%;
}
.carousel .el-carousel .el-carousel--horizontal {
  // height: 100%;
  width: 100%;
}
::v-deep .carouselBox {
  text-align: center;
  // margin: 0 auto;
  width: 80%;
  //height: 300px;
  .carousel {
    width: 100%;
    height: 400px;
    .el-car-item {
      width: 100%;
      height: 300px;
      display: flex;
      .divSrc {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 300px;
        background: #fff;
        margin-right: 30px;
        .img1 {
          // width: 70%;
          width: 125px;
          height: 175px;
          background: center;
          // margin-right: 150px;
          // height: 150px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 35px;
          margin: 0 auto;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      //	background: red !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img1 {
      width: 100%;
      height: 150px;
      // margin-right: 20px;
      cursor: pointer;
      background: center;
    }
  }
}

//安思新闻标题

.firnew {
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: 12%;
  margin-bottom: 2%;
}
.firnew2 {
  display: flex;
  flex-direction: column;
  line-height: 0;
}
.pfirnew1 {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  margin: 0 !important;
  color: #790405 !important;
  margin-right: 6px !important;
}
.pfirnew2 {
  color: #790405 !important;
  font-weight: normal !important;
  font-size: 1em !important;
  margin-bottom: 5px !important;
}
.pfirnew3 {
  color: #790405 !important;
  font-size: 8px !important;
  font-weight: normal !important;
  margin-bottom: 5px !important;
}

.carouselBox .carousel .el-car-item .divSrc .title {
  height: 40px;
}

.servericon {
  margin: 5% 0 5% 0;
}

.icons {
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 0px 20% 0px 20%;
  margin: 4% 0 5% 0;
}

.spanicon {
  border-left: 1px solid #d9d9d9;
  margin-left: -1px;
  padding-left: 5px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.iconss {
  padding-top: 4%;
  padding-bottom: 4%;
}
.iconss p {
  font-size: 0.8rem;
}

.textdoc {
  font-size: 8px;

  // width: 70%;
}

.textdoc2 {
  font-size: 8px;
}

.three img {
  width: 100%;
}

.docbut {
  background-color: rgb(255, 255, 255);
  border: 0px;
  width: 70%;
}
.docdoc {
  width: 90%;
  white-space: nowrap;

  /*2. 超出的部分隐藏*/
  overflow: hidden;

  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
.cardspan {
  // height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.popover {
  border: 0px;
  text-align: left;
  // line-height: 150%;
  background-color: #fff;
}

.xiangqing {
  font-size: 8px;
}

.shanchang {
  color: rgb(100, 100, 100) !important;
  font-size: 8px;
}
</style>